import { ProductAlgolia, mapProducts } from "~/utils/mapProducts"

export const fetchLastViewedProducts = async (
  indexProducts: any,
  lastViewedProductsCookie: string[]
) => {
  if (!lastViewedProductsCookie || lastViewedProductsCookie.length === 0) {
    return []
  }

  try {
    return await indexProducts
      .getObjects(lastViewedProductsCookie.map((code) => `product_${code}`))
      .then((response: any) => {
        if (response?.results && response?.results.length > 0) {
          const products = response?.results
            .filter((i: ProductAlgolia) => i && i.inStock)
            .reverse()
          return mapProducts(products, null, "ALGOLIA")
        } else {
          return []
        }
      })
  } catch (error) {
    console.warn("ERROR FETCH LAST VIEWED PRODUCTS", error)
    return []
  }
}
